import React from "react";
import Menu from "../../components/menu";
import CarouselHome from "../../components/carouselHome";
import aspasUp from "../../assets/icons/aspasUp.svg";
import aspasDown from "../../assets/icons/aspasDown.svg";
import { Clients, Container, Logo } from "./styled";
import Footer from "../../components/footerSite";
import assuranceicon from "../../assets/icons/assuranceicon.svg";
import brazilicon from "../../assets/icons/brazilicon.svg";
import workicon from "../../assets/icons/workicon.svg";
import stampicon from "../../assets/icons/stampicon.svg";
import WppButton from "../../components/wppButton";
import { useInitialScroll } from "../../hooks/useInitialScroll";
import home_Intro from "../../assets/images/criancas-abracando.webp"
import home_description from "../../assets/images/home_copacabana.webp"

import ayoshii from "../../assets/logos/clients/ayoshii.png"
import plaenge from "../../assets/logos/clients/plaenge.png"
import yticon from "../../assets/logos/clients/yticon.png"
import countryClube from "../../assets/logos/clients/country-clube.png"
import aabb from "../../assets/logos/clients/aabb.jpg"
import unicesumar from "../../assets/logos/clients/unicesumar.png"
import belmondCopacabanaPalace from "../../assets/logos/clients/belmond-copacabana-palace.png"
import fairmont from "../../assets/logos/clients/fairmont.png"

export default function Home() {
  useInitialScroll();

  return (
    <>
      <Menu />
      <WppButton />
      <Container>
        <CarouselHome />
        <div className="One">
          <img className="aspasUp" src={aspasUp} alt="aspas abertas"></img>
          <h2>
            SOMOS A MAIOR E MELHOR INDÚSTRIA ESPECIALIZADA EM {" "}
            <strong>proteção infantil e pet </strong> EM PISCINAS NO BRASIL
          </h2>
          <div className="aspasDown">
            <img src={aspasDown} alt="aspas abertas"></img>
          </div>
        </div>
        <div className="textAndVideo">
          <div className="contentLimit">
            <div className="shortDescription">
              <h3>
                Protectpool, a empresa que prioriza a segurança de quem você
                ama
              </h3>
              <p>
                Nós levamos a segurança da sua família e de quem você ama como
                nossa prioridade!
              </p>
              <p>
                Especialista e Líder de mercado, a Protectpool atua em todo o 
                território nacional, contando com mais de 180 unidades de atendimento. Ao longo de mais de 11 anos, 
                mais de 21 mil famílias impactadas, mais de 50 mil crianças 
                protegidas e mais de 400 mil metros de Cerca Removível instaladas.
              </p>
            </div>
            <div className="imagemPresention">
              <img
                src={home_Intro}
                alt="Foto de criancas se apoiando na tela"
              ></img>
            </div>
          </div>
        </div>
        <div className="grayBg">
          <div className="iconList">
            <div>
              <img src={assuranceicon} alt="simbolo de garatina" />
              <p>Garantia <br className="hiddenMobile"></br>de 5 anos</p>
            </div>
            <div>
              <img src={brazilicon} alt="simbolo Brasil" />
              <p>Entrega para <br className="hiddenMobile"></br>todo o Brasil</p>
            </div>
            <div>
              <img src={workicon} alt="simbolo de ferramenta" />
              <p>100% <br className="hiddenMobile"></br>removível</p>
            </div>
            <div>
              <img src={stampicon} alt="simbolo de medalha de qualidade" />
              <p>Mais de 50 mil <br className="hiddenMobile"></br>crianças protegidas</p>
            </div>
          </div>
        </div>

        {/* Mobile */}

        <div className="shortDescriptionLight hiddenWeb">
          <h3>Quem disse que sofisticação e segurança não combinam?</h3>
          <p>
            A proteção que a família precisa, com a sofisticação que o ambiente
            exige. Você pode personalizar a sua proteção, deixando o ambiente
            bem mais harmônico.
          </p>
        </div>
        <div className="imagemPresention hiddenWeb">
          <img
            src={home_description}
            alt="Foto de paisagem com piscina e a protecao da Protectpool"
          ></img>
        </div>
        
        {/* WEB */}

        <div className="shortDescriptionLight hiddenMobile">
          <h3>Quem disse que sofisticação e segurança não combinam?</h3>
          <p>
            A proteção que a família precisa, com a sofisticação que o ambiente
            exige. Com diversas cores disponíveis em catálogo, você pode
            personalizar a sua proteção, trazendo mais harmonia e elegância para
            seu espaço de lazer.
          </p>
        </div>
        <div className="imagemPresention hiddenMobile">
          <img
            src={home_description}
            alt="Foto de paisagem com piscina e a protecao da Protectpool"
          ></img>
        </div>


        {/* Clients */}
        <Clients>
          <h3>Clientes atendidos</h3>
          <div className="logos">
            <Logo src={ayoshii} alt="AYoshii logo" />
            <Logo src={plaenge} alt="Plaenge logo" />
            <Logo src={yticon} alt="Yticon logo" />
            <Logo src={countryClube} alt="Country clube logo" />
            <Logo src={aabb} alt="AABB logo" />
            <Logo src={unicesumar} alt="Unicesumar logo" />
            <Logo src={belmondCopacabanaPalace} alt="Belmond Copacabana Palace logo" />
            <Logo src={fairmont} alt="Fairmont Copacabana logo" />
          </div>
        </Clients>

        <div className="col-2">
          <div className="description left">
            <h3>
              NÃO É CERCA.<br />É A TRANQUILIDADE DA SUA FAMÍLIA.
            </h3>
            <p>
              Já pensou em deixar sua casa com as portas abertas, seus filhos brincando 
              livremente, você realizando suas atividades diárias sem stress ou preocupação? Chegou o momento.
            </p>
            <br />
            <p>
              Com a Protectpool, a proteção dos seus filhos e sua tranquilidade é garantida. 
              Proporcionamos mais tempo de qualidade para a sua família.
            </p>
          </div>
          <div className="YoutubeVideo">
            <iframe
              src="https://www.youtube.com/embed/jzaYHfakoqc?si=k2bWS7pQsNW1XLbB"
              title="YouTube video, portão com trava magnética"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen={false}
            ></iframe>
          </div>
        </div>

        <div className="col-2 videoAndDescription">
          <div className="YoutubeVideo">
            <iframe
              src="https://www.youtube.com/embed/Euz55ubOeec"
              title="YouTube video, teste de resistência"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen={false}
            ></iframe>
          </div>
          <div className="description right">
            <h3>
              Quando o assunto é segurança, não há espaço para segunda linha!
            </h3>
            <p>
              Com um rigoroso processo de produção, garantimos a mais alta 
              qualidade e tecnologia de insumos e matérias primas de produção, 
              entregando a melhor proteção infantil e pet do mercado nacional (e mundial). 
            </p>
            <br />
            <p>
              Se tratando da vida de seus filhos, não há espaço para materiais de baixa 
              qualidade ou segunda linha. Confira abaixo nosso teste de resistência nas 
              hastes de alumínio.
            </p>
          </div>
        </div>

        <div className="col-2">
          <div className="description left">
            <h3>
              O PODER DA DECISÃO CERTA NA PROTEÇÃO DE SEUS FILHOS
            </h3>
            <p>
              Veja este depoimento de uma mãe (Katy Dias) que após muitas decepções com outra empresa do mercado,
              optou por recorrer às soluções Protectpool e finalmente conquistou o que tanto desejava: tranquilidade!
            </p>
          </div>
          <div className="YoutubeVideo">
            <iframe
              src="https://www.youtube.com/embed/yuAHqOrvPiI"
              title="YouTube video, teste de resistência"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen={false}
            ></iframe>
          </div>
        </div>
        <Footer />
      </Container>
    </>
  );
}
